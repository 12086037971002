(function (window) {
    var searchBtn = document.getElementById('search-btn');
    var closeSearchBtn = document.getElementById('close-search-btn');
    var searchBar = document.getElementById('search-bar');
    var pageSize = 10;

    function initEvents() {
        searchBtn.addEventListener('click', function () {
            var searchDiv = document.getElementById("search-div");
            searchDiv.style.width = "300px";
            classie.add(searchDiv, 'panel-opened');
            document.getElementById("search-btn").style.display = "none";
        });

        closeSearchBtn.addEventListener('click', function () {
            document.getElementById("search-btn").style.display = "block";
            var searchDiv = document.getElementById("search-div");
            searchDiv.style.width = "0";
            classie.remove(searchDiv, 'panel-opened');
        });

        searchBar.addEventListener('keyup', debounce(function () {
            var $this = $(this);
            var n1 = $this.val();
            search(1);
        }, 500));
    }

    function init() {
        initEvents();
    }

    function debounce(func, wait, immediate) {
        var timeout;
        return function () {
            var context = this, args = arguments;
            var later = function () {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    };

    function searchResultClickCallback(edificioId, espacoId) {
        return function () {
            document.location.href = edificioId + ".html?room=" + espacoId;
        }
    }

    function search(pageIndex) {
        var $pagination = $('#search-pagination');
        var defaultOpts = {
            totalPages: 20
        };
        var campiId = null;
        if ($('.uminho-links').length) {
            campiId = ($('.uminho-links').children('a').eq(1)).attr('href').substring(1, 3);
        }
        var term = searchBar.value.trim();
        if (term != "" && term != undefined && term != null) {
            roomService.searchByTerm(campiId, term, pageIndex, pageSize)
            .then(function (results) {
                $('#search-results').empty();
                if (results.data.ListaEspacos.length > 0) {
                    for (var i = 0; i < results.data.ListaEspacos.length; i++) {
                        var res = results.data.ListaEspacos[i];
                        var result = '<div id="search-result_' + res.EspacoId + '" class="search-result">\
                                        <a>' + res.EspacoDesignacao + '</a>\
                                        <span>' + res.CampusDesignacao + ' | ' + res.EdificioDesignacao + '</span>\
                                      </div>';
                        $('#search-results').append(result);

                        // Result click handler
                        $("#search-results #search-result_" + res.EspacoId).click(searchResultClickCallback(res.EdificioId, res.EspacoId));

                        var numPages = Math.ceil(results.data.TotalCount / pageSize);
                        var currentPage = $pagination.twbsPagination('getCurrentPage');

                        $pagination.twbsPagination('destroy');
                        $pagination.twbsPagination($.extend({}, defaultOpts, {
                            startPage: currentPage,
                            totalPages: numPages,
                            visiblePages: 5,
                            first: '',
                            last: '',
                            prev: '&laquo;',
                            next: '&raquo;',
                            initiateStartPageClick: false,
                            onPageClick: function (event, page) {
                                search(page);
                            }
                        }));

                    }
                } else {
                    $('#search-pagination').empty();
                    var noResults = '<div class="no-results">\
                                        <span>Sem resultados.</span>\
                                      </div>';
                    $('#search-results').append(noResults);
                }
            })
            .catch(function (error) {
                console.error(error);
                $('#search-results').empty();
                $('#search-pagination').empty();
                var errorMsg = '<div class="error-result">\
                                    <span>Ocorreu um erro durante a pesquisa.<br>Tente novamente mais tarde.</span>\
                                </div>';
                $('#search-results').append(errorMsg);
            });
        } else {
            $('#search-results').empty();
            $('#search-pagination').empty();
        }
    }

    init();

})(window);